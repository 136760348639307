$bestparaquatlawyers-green: #00a259;
$bestparaquatlawyers-green-dark: #00a259;
$bestparaquatlawyers-green-hover: #00a259;
$bestparaquatlawyers-green-button: #00a259;
$bestparaquatlawyers-grey: #585858;

:export {
    green: $bestparaquatlawyers-green;
    darkGreen: $bestparaquatlawyers-green-dark;
    hover: $bestparaquatlawyers-green-hover;
    greenButton: $bestparaquatlawyers-green-button;
    grey: $bestparaquatlawyers-grey;
}
